import { createSignal } from 'solid-js'
import { LoadingState } from '~/services/loading/loading.interface'

const [loadingState, setLoadingState] = createSignal<LoadingState>({
  loading: false,
  message: null
}, {
  equals: false
})

export {
  loadingState,
  setLoadingState
}